import { computed } from 'vue'

import type { T3LabelProps } from '~shared/types'

const useLabel = (props: T3LabelProps) => {
  return computed(() => {
    if (!props.labelTitle) return {}

    return {
      title: props.labelTitle,
      accentColor: props.labelAccentColor,
      withBackground: !!props.labelBackground
    }
  })
}

export default useLabel
